import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure,
    ModalCloseButton,
    useToast,
    Spinner,
    Input,
  } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react';
import { CiCirclePlus } from 'react-icons/ci'
import { AuthContext } from '../../../../context/auth';
import { GET, POST } from '../../../../utils/requests';
import { getuseruri } from '../../../../uri';
import { FaCarAlt } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { FcHome } from "react-icons/fc";



export function Delivery({callback, prods}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {currentUser} = useContext(AuthContext);
    const [data, setData] = useState({phone_additional:"", address_id:""});
    const [load, setLoad] = useState(false);
    const [address, setAddress] = useState([]);
    const [deliv, setDeliv] = useState({});
    const [rejectdeliv, setRejectDeliv] = useState({});
    const toast = useToast();

    const handlecall = () => {
      callback();
    }

    const Sub = async() => {
      if(data.address_id === "" || data.phone_additional === ""){
        return toast({title: "Уучлаарай", description: "Мэдээлэл дутуу байна", status: "warning", duration: 5000,isClosable: true});
      }
      setLoad(true);
      const res = await POST({uri: getuseruri+`/delivery-register`, data:{address_id: data.address_id, phone_additional: data.phone_additional, token: currentUser}});
      if(res.status === 200){
        onClose();
        handlecall();
        toast({title: "Хүргэлт амжилттай бүртгэгдлээ", description: "Заан каргог сонгодог танд баярлалаа", status: "success", duration: 5000,isClosable: true,});
        setData({...data, trackcode:"", note:""});
      }
      setLoad(false);
    }

    const SubDelete = async() => {
      setLoad(true);
      const res = await POST({uri: getuseruri+`/delivery-reject`, data:{token: currentUser, ids: rejectdeliv.id}});
      if(res.status === 200){
        onClose();
        handlecall();
        toast({title: "Амжилттай", description: "Та дахин хүргэлтэнд бүртгүүлэх боломжтой боллоо", status: "success", duration: 5000,isClosable: true,});
      }
      setLoad(false);
    }

    const Get = async()=> {
      setLoad(true);
      const res = await GET(getuseruri+'/address'+`?token=${currentUser}`);
      setAddress(res.data.data);
      setDeliv(res.data.deliv);
      setRejectDeliv(res.data.reject);
      setLoad(false);
    }

    // useEffect(() => {
    //   Get();
    // }, []);

    const loadBalance = () => {
      onOpen();
      Get();
    }

    const addAddress = (e) => {
      setData({...data, address_id: e});
      console.log(e)
    }

    return (
      <>
        <div onClick={loadBalance} className='flex justify-center'>
            <div className='bg-[#55A2E2] ring-2 ring-gray-300 flex items-center justify-center gap-2 w-full mt-2 cursor-pointer
                rounded-xl py-2'>
                <FaCarAlt  size={18}/>
                <h1>Хүргэлт</h1>
            </div>
        </div>
  
        <Modal isOpen={isOpen} onClose={onClose} placement='bottom'>
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader>Modal Title</ModalHeader> */}
            <ModalCloseButton />
            <ModalBody>
                <div className='mb-10 font-Roboto mt-8'>
                    <div className='flex gap-2'>
                      <TbTruckDelivery size={20}/>
                      <h1 className='text-sm mb-2'>Хүргэлтийн мэдээлэл</h1>
                    </div>
                    
                    {
                      rejectdeliv ?
                        <>
                          <h1 className='text-center mt-4 font-semibold'>Таны хүргэлт цуцлагдсан байна.</h1>
                          <h1 className='text-center mt-4 font-semibold'>Шалтгаан нь :</h1>
                          <h1 className='text-center mt-1'>{rejectdeliv.explanation}</h1>
                        </>
                      :
                      <>
                        {
                          deliv?
                          <h1 className='text-center mt-4 font-semibold'>Та хүргэлтэнд бүртгүүлсэн байна.</h1>
                          :
                          <div>
                            {
                              prods === 0 ?
                              <h1 className='text-center mt-4 font-semibold'>Таньд ирсэн бараа байхгүй байна.</h1>
                              :
                              <>
                                <h1 className='text-sm mt-4'>Таньд ирсэн <span className='font-bold text-md'>{prods}</span> бараа байна.Та хүргэлтэнд бүртгүүлсэн тохиолдолд таны ирсэн бүх ачааг хүргэлтээр гаргах болно.</h1>
                                <div>

                                  <h1 className='mt-2 font-bold'>Нэмэлт утасны дугаар</h1>
                                  <Input value={data.phone_additional} onChange={(e) => setData({...data, phone_additional: e.target.value})} className='mt-1' placeholder='Утасны дугаар бичих' type='number'/>
                                  <h1 className='font-bold mt-2'>Хүргүүлэх хаяг сонгох</h1>
                                  <div>
                                    {
                                      address.map((it, index) => {
                                        return(
                                          <div className='mt-2 font-Roboto' key={index}>
                                              <div className={Number(data.address_id) === it.id ? 'border rounded-md border-blue-600 bg-blue-100 p-4  cursor-pointer'
                                                :'border rounded-md border-gray-300 p-4 hover:bg-gray-100 cursor-pointer'
                                              } onClick={()=>addAddress(it.id)}>
                                                <div className='flex items-center gap-2'>
                                                  <FcHome  className='rounded-full ring-1 ring-gray-300 p-1' size={24}/>
                                                  <h1>Хаяг</h1>
                                                </div>
                                                <div className='mt-1 ml-2'>
                                                  <h1 className='text-sm'>{it.address}</h1>
                                                  <h1 className='text-md'>{it.city}, {it.district}, {it.khoroo}</h1>
                                                </div>
                                              </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </>
                            }
                          </div>
                        }
                      </>
                    }
                    
                    <div className='mt-6 flex justify-center text-white'>
                      {
                        load?
                        <div className='bg-[#349730] ring-2 ring-gray-300 flex items-center justify-center gap-2
                            rounded-xl py-2 w-full'>
                            <Spinner/>
                        </div>
                        :
                        <>
                          {
                            rejectdeliv ?
                            <>
                              {
                                prods !== 0 &&
                                <div onClick={SubDelete} className='bg-[#349730] ring-2 ring-gray-300 flex items-center justify-center text-md gap-2 cursor-pointer
                                    rounded-xl py-2 w-full'>
                                    <CiCirclePlus size={30}/>
                                    <h1>Ойлголоо</h1>
                                </div>
                              }
                            </>
                            :
                            <>
                              {
                                deliv?
                                ""
                                :
                                <>
                                  {
                                    prods !== 0 &&
                                    <div onClick={Sub} className='bg-[#349730] ring-2 ring-gray-300 flex items-center justify-center gap-2 cursor-pointer
                                        rounded-xl py-2 w-full'>
                                        <CiCirclePlus size={30}/>
                                        <h1>Хүргэлт бүртгэх</h1>
                                    </div>
                                  }
                                </>
                              }
                            </>
                          }
                        </>
                      }
                    </div>
                </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }