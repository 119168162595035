import React from 'react';
import { PiNotebookFill } from "react-icons/pi";
import { FiPhoneCall } from "react-icons/fi";
import {AspectRatio} from '@chakra-ui/react'


const Contact = () => {
  return (
    <div className='text-black mt-8'>
         <div className='flex items-center gap-4'>
            {/* <TfiWrite /> */}
            <div>
                <h1 className='font-bold text-md uppercase text-xl'>Холбоо барих</h1>
                <p>Contact</p>
            </div>
        </div>

        <div className='mt-14'>
            <div className='flex justify-center'>
                <FiPhoneCall size={40}/>
            </div>
            <h1 className='text-center uppercase text-sm mt-4 font-bold'>Хаяг байршил :</h1>
            <p className='text-sm text-gray-600 text-center mt-4'> Баянгол дүүрэг 7р хороо гэмтэлийн эмнэлгийн замаар яваад Рич Хилл зочид  буудлын замын урд талд (ертөнцийн зүгээр) 42А байр
            </p>
            <p className='text-center uppercase font-bold text-sm mt-4'>Оффисс утас: 90344775, 96700805 </p>

            <div className='mt-4'>
            {/* <AspectRatio ratio={16 / 18}>
              <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10696.28302719892!2d106.92997661232594!3d47.91566835501961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9693006af99379%3A0x403df1ee7e8a891d!2sSain%20Cargo!5e0!3m2!1sen!2smn!4v1716106237350!5m2!1sen!2smn' />
            </AspectRatio> */}
            </div>
        </div>
    </div>
  )
}

export default Contact